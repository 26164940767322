import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonHeader,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { setLicensesCount } from "@src/app/store/session";
import { logEvent } from "@src/appV2/lib/analytics";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { useGetLicenses } from "@src/appV2/Licenses/api/useGetLicenses";
import { USER_EVENTS } from "@src/constants/userEvents";
import { useDefinedUserId } from "@store/helperHooks";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { LICENSE_STATUSES_VALUE_MAPPING } from "./constants";
import { ILicenseDetails } from "./interfaces";
import { License } from "./license";
import { getLicensesCountByStatus, getLicensesSummaryText } from "./utils";

const LicenseManager: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useDefinedUserId();
  const [expandedAccordion, setExpandedAccordion] = useState<LicenseStatuses[]>([
    LicenseStatuses.REJECTED,
    LicenseStatuses.EXPIRED,
  ]);

  const onAddLicenseClick = () => {
    logEvent(USER_EVENTS.ADD_LICENSE_TAPPED);
    history.push(TabRouterPath.ADD_LICENSE);
  };

  const { isLoading, data: licenses } = useGetLicenses(
    {
      workerId: userId,
    },
    {
      userErrorMessage: "Failed to get licenses",
    }
  );
  const licensesGroupedByStatus = groupBy(licenses ?? [], "status");

  useEffect(() => {
    if (!isDefined(licenses)) {
      return;
    }

    const licensesCountByStatus = getLicensesCountByStatus(licenses as ILicenseDetails[]);
    dispatch(setLicensesCount(userId));
    logEvent(USER_EVENTS.LICENSES_VIEWED, {
      activeCount:
        (licensesCountByStatus[LicenseStatuses.ACTIVE] || 0) +
        (licensesCountByStatus[LicenseStatuses.EXPIRING] || 0),
    });
  }, [licenses, dispatch, userId]);

  const licensesSummaryText = useMemo(() => {
    if (!licenses) {
      return [];
    }

    const licensesCountByStatus = getLicensesCountByStatus(licenses as ILicenseDetails[]);
    return getLicensesSummaryText(licensesCountByStatus);
  }, [licenses]);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Licenses</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={isLoading} backdropDismiss={true} />
      <IonContent className="license-manager">
        {licenses?.length ? (
          <>
            <IonText className="licenses-summary-text">
              <p style={{ marginTop: "20px", marginBottom: "0px" }}>
                {licensesSummaryText.map((summaryText, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={index}>
                      {!!index && ", "}
                      <span style={{ whiteSpace: "nowrap" }}>{summaryText}</span>
                    </span>
                  );
                })}
                {}
              </p>
            </IonText>
            <Stack
              spacing={1}
              sx={{
                padding: 2,
              }}
            >
              {[
                LicenseStatuses.REJECTED,
                LicenseStatuses.EXPIRED,
                LicenseStatuses.PENDING,
                LicenseStatuses.ACTIVE,
              ].map(
                (licenseStatus) =>
                  licensesGroupedByStatus[licenseStatus] && (
                    <Accordion
                      key={licenseStatus}
                      square
                      expanded={expandedAccordion.includes(licenseStatus)}
                      onChange={() => {
                        if (expandedAccordion.includes(licenseStatus)) {
                          setExpandedAccordion(
                            expandedAccordion.filter((accordion) => accordion !== licenseStatus)
                          );
                        } else {
                          setExpandedAccordion([...expandedAccordion, licenseStatus]);
                        }
                      }}
                      sx={{ borderBottom: 1 }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
                        <Text variant="h2">{LICENSE_STATUSES_VALUE_MAPPING[licenseStatus]}</Text>
                        <Text sx={{ marginLeft: 1 }}>
                          (
                          {pluralize(
                            "license",
                            licensesGroupedByStatus[licenseStatus].length,
                            true
                          )}
                          )
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        {licensesGroupedByStatus[licenseStatus].map((license) => {
                          return <License license={license as ILicenseDetails} key={license.id} />;
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )
              )}
            </Stack>
            <div style={{ height: "60px" }}></div>
            {/* Empty div to scroll license card above fab button*/}
          </>
        ) : (
          <IonText className="add-license-text">
            <p style={{ marginTop: "20px" }}>
              {isLoading ? "Loading Licenses..." : "Add a License to start working!"}
            </p>
          </IonText>
        )}
        <IonFab vertical="bottom" slot="fixed" className="add-license-fab">
          <IonButton
            data-testid="add-license-button"
            expand="block"
            className="add-license-button"
            fill="outline"
            onClick={onAddLicenseClick}
          >
            Add License
          </IonButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export { LicenseManager };
